import React from 'react'
// import logo from "./logo2.png";
// import logoTransparent from "./logo-transparent.png";
// import logoLight from "./logo-light.png";
// import logoDark from "./logo-dark.png";
import logoLg from './logo-lg.jpg'

import {
  Box,
  Container,
  ThemeProvider,
  Typography,
  useMediaQuery,
  createTheme,
  Paper,
  Alert,
  Link,
  LinearProgress,
} from '@mui/material'
import EscalationForm from './EscalationForm'
import { useParams, useFetch } from './hooks'
import { useEscalateTicketMutation, useValidateRequestQuery } from './store/baseApi'

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: {
            main: '#f9dc5c',
          },
          secondary: {
            main: '#88c4dd',
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: '#f9dc5c',
          },
          secondary: {
            main: '#88c4dd',
          },
        }),
  },
})

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  /**
   * light mode color
   * backgroundColor: "#f9dc5c",
   */
  const theme = React.useMemo(
    () => createTheme(getDesignTokens(prefersDarkMode ? 'dark' : 'light')),
    [prefersDarkMode],
  )

  // console.log({ theme })

  const params = useParams()
  const ticketId = params.get('ticketId') // [srnumber]
  const boardName = params.get('boardName') // [srboard]
  const companyId = params.get('companyId') // [companyid]
  const companyRecordId = params.get('companyRecordId') // [companyrecordid]
  const serviceSource = params.get('serviceSource') // [srsource]
  const contactRecordId = params.get('contactRecordId') // [contactrecordid]

  const paramValues = {
    ticketId,
    boardName,
    companyId,
    companyRecordId,
    serviceSource,
    contactRecordId,
  }

  const {
    data: validateResult,
    isError: validateIsError,
    isFetching: validateIsFetching,
    isSuccess: validateIsSuccess,
  } = useValidateRequestQuery(paramValues)

  const [
    escalateTicket,
    {
      data: escalationResult,
      isError: escalateIsError,
      isFetching: escalateIsFetching,
      isSuccess: escalateIsSuccess,
    },
  ] = useEscalateTicketMutation()

  return (
    <ThemeProvider theme={theme}>
      <Paper
        elevation={1}
        sx={{
          maxWidth: '100%',
          borderRadius: 0,
          border: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor:
            theme.palette.mode === 'light'
              ? theme.palette.primary.main
              : theme.palette.background.default,
        }}
      >
        <Paper
          elevation={1}
          sx={{
            padding: 4,
            mt: 4,
            minHeight: '15vh',
            backgroundColor: '#ffffff',
          }}
        >
          <img src={logoLg} alt="Covenant Technology Solutions Logo" width={306} height={167} />
        </Paper>
        <Typography variant="h4" sx={{ mt: 3, fontSize: 'calc(12px + 3vmin)' }}>
          Emergency Escalation
        </Typography>
        <Paper
          elevation={1}
          sx={{
            minHeight: '75vh',
            pt: 1,
            borderRadius: 0,
            border: 0,
            boxShadow: 'none',
            width: '100%',
            backgroundColor:
              theme.palette.mode === 'light'
                ? theme.palette.primary.main
                : theme.palette.background.default,
          }}
        >
          <Container component="main" maxWidth="lg" sx={{ mt: 3, boxShadow: 'none', border: 0 }}>
            {validateIsFetching && (
              <Paper elevation={2}>
                <LinearProgress />
              </Paper>
            )}
            {validateIsSuccess && validateResult?.validated > 0 && (
              <Paper
                elevation={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  // borderRadius: "1vh",
                  // borderColor: "black",
                  // borderWidth: "1px",
                  // borderStyle: "solid",
                  padding: 2,
                  width: 'inherit',
                }}
              >
                <Typography component="p" variant="p" sx={{ pb: 1, pl: 1 }}>
                  By checking the box, you acknowledge the need for support outside regular business
                  hours and acknowledge the possibility of being billed for any extra costs incurred
                  as a result of the after-hours service if it is not included in your agreement.{' '}
                </Typography>
                <Typography component="p" variant="p" sx={{ pb: 1, pl: 1 }}>
                  Please feel free to provide any additional information or specific details related
                  to the support request in the box below. Your input will assist us in better
                  understanding and addressing your needs.{' '}
                </Typography>
                <Typography component="p" variant="p" sx={{ pb: 1, pl: 1 }}>
                  Please note that non-emergency requests will be handled during regular business
                  hours. If your request does not require immediate attention, we will address it at
                  the earliest opportunity during our standard operating hours.
                </Typography>
                <EscalationForm {...paramValues} />
              </Paper>
            )}
            {((validateIsSuccess && !validateResult?.validated) || validateIsError) && (
              <>
                <Alert severity="warning" sx={{ mb: 1 }}>
                  Unable to load information.
                </Alert>
                <Paper
                  elevation={2}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    // borderRadius: "1vh",
                    // borderColor: "black",
                    // borderWidth: "1px",
                    // borderStyle: "solid",
                    padding: 2,
                    width: 'inherit',
                  }}
                >
                  <Typography component="p" variant="p">
                    For assistance please call the helpdesk at{' '}
                    <Link href="tel:503-715-3540">503-715-3540</Link>.
                  </Typography>
                </Paper>
              </>
            )}
          </Container>
        </Paper>
      </Paper>
    </ThemeProvider>
  )
}

export default App
