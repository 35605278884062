import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: baseQuery(),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    validateRequest: builder.query({
      query: (params) => ({ path: '/api/validateRequest', params }),
    }),
    escalateTicket: builder.mutation({
      query: ({ additionalNotes, ...rest }) => ({
        method: 'post',
        path: '/api/escalateTicket',
        data: {
          ...rest,
          additionalNotes,
        },
      }),
    }),
  }),
})

export const { useEscalateTicketMutation, useValidateRequestQuery } = baseApi
