import React from 'react'
import { Formik, Field, Form } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import { LinearProgress, Button, Box, Typography, Alert } from '@mui/material'
import axios from 'axios'
import { useEscalateTicketMutation } from './store/baseApi'
import { useParams } from './hooks'

export default function EscalationForm({ ...rest }) {
  const params = useParams()
  const ticketId = params.get('ticketId')

  const [
    escalateTicket,
    {
      data: escalationResult,
      isError: escalateIsError,
      isFetching: escalateIsFetching,
      isSuccess: escalateIsSuccess,
    },
  ] = useEscalateTicketMutation()

  const handleSubmit = async ({ additionalNotes }) => {
    return await escalateTicket({ ...rest, additionalNotes })
  }

  return (
    <Box component="div" sx={{ width: 'inherit' }}>
      {escalateIsError && <Alert severity="warning">Could not complete escalation</Alert>}
      {!escalateIsSuccess && (
        <Formik
          initialValues={{
            confirm: false,
            additionalNotes: '',
          }}
          onSubmit={handleSubmit}
        >
          {({ submitForm, isSubmitting, values }) => (
            <Form>
              <Field
                component={CheckboxWithLabel}
                name="confirm"
                type="checkbox"
                sx={{ ml: 2 }}
                Label={{ label: 'I Agree' }}
              />
              <br />
              <Field
                component={TextField}
                type="text"
                label="Additional Comments"
                name="additionalNotes"
                sx={{ ml: 1, mr: 2 }}
                fullWidth
                multiline
              />
              <br />
              {isSubmitting && <LinearProgress sx={{ ml: 2, mr: 1 }} />}
              <br />
              <Button
                variant="contained"
                color="primary"
                disabled={
                  isSubmitting ||
                  !values.confirm ||
                  escalateIsError ||
                  escalateIsSuccess ||
                  escalateIsFetching
                }
                onClick={submitForm}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      )}
      {escalateIsSuccess && <Alert severity="success">Sucessfully escalated ticket</Alert>}
    </Box>
  )
}
